<template>
  <el-row class="instructionPageWrapper">
    <el-row class="contentRow">
      <!-- 导航菜单 -->
      <el-col :span="3" class="menuCol">
        <el-menu
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose">
          <el-submenu v-for="(item,index) in menuList" :key="index" :index="index + ''">
            <template slot="title">
              <span  @click="skipTo(item.id)">{{item.label}}</span>
            </template>
            <el-menu-item v-for="(child, i) in item.childList" :key="i" :index="index + '-' + i +''" @click="skipTo(child.id)">
              <span slot="title">{{child.label}}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
      <!-- 内容区域 -->
      <el-col :span="21" class="contentCol">

        <!-- 系统简介 -->
        <h1 id="menu1">系统简介</h1>
          <!-- 系统操作流程 -->
          <h2 id="menu1child1">系统操作流程</h2>
          <img src="@/assets/img/sysimg_1.png" />
          <!-- 系统流程演示 -->
          <h2 id="menu1child2">系统流程演示</h2>
          <img src="@/assets/img/sysimg_2.png" />
        
        <!-- 刊登管理 -->
        <h1 id="menu2">刊登管理</h1>
          <!-- EBAY listing刊登管理 -->
          <h2 id="menu2child1">EBAY listing刊登管理</h2>
            <h4>1.	进入ebay listing 管理界面：</h4>
            <img src="@/assets/img/publish_1.png" />
            <h4>2.	点击修改可以进入编辑界面，基础信息可以编辑价格、数量等</h4>
            <img src="@/assets/img/publish_2.png" />
            <h4>3.	点开分类&Item Specifics可以编辑分类及子类明细信息</h4>
            <img src="@/assets/img/publish_3.png" />
            <h4>4.	点击标题&副标题可以修改标题</h4>
            <img src="@/assets/img/publish_4.png" />
            <h4>5.	图片&描述可以批量操作图片等，最终点击保存来完成listing修改操作</h4>
            <img src="@/assets/img/publish_5.png" />

          <!-- Ebay listing下架 -->
          <h2 id="menu2child2">Ebay listing下架</h2>
            <h4>1.	打开下载记录界面可以查看已下架产品记录</h4>
            <img src="@/assets/img/soldout_1.png" />
            <h4>2.	打开记录可以查看该listing修改历史记录</h4>
            <img src="@/assets/img/soldout_2.png" />
            <h4>3.	可以直接点击指令按钮，下发指令</h4>
            <img src="@/assets/img/soldout_3.png" />
        
        <!-- 营销管理 -->
        <h1 id="menu3">营销管理</h1>
        
          <!-- Ebay关联营销 -->
          <h2 id="menu3child1">Ebay关联营销</h2>
            <h4>1.	营销——eBay关联营销，单击添加关联营销模板：</h4>
            <img src="@/assets/img/marketing_1.png" />
            <h4>2.	根据营销需求，选择获取关联的刊登的条件</h4>
            <h5>a)	item来源：根据条件获取</h5>
            <img src="@/assets/img/marketing_2.png" />
            <h5>b)	item来源：指定item</h5>
            <img src="@/assets/img/marketing_3.png" />
            <h4>3.	设置好关联营销的模板后即可应用：</h4>
            <img src="@/assets/img/marketing_4.png" />

          <!-- eBay打折设置 -->
          <h2 id="menu3child2">eBay打折设置</h2>
            <h4>1.	先到营销--eBay打折,位置添加一个打折模板</h4>
            <img src="@/assets/img/soldout_1.png" />
            <h4>2.	设置好模板后，到eBay在线直接插入打折模板</h4>
            <img src="@/assets/img/soldout_2.png" />
            <h4>3.	到打折模板开始时间后，在线开始打折，并在item旁边显示一个折字</h4>
            <img src="@/assets/img/soldout_3.png" />
        
        <!-- 商品管理 -->
        <h1 id="menu4">商品管理</h1>
          <!-- 商品查询 -->
          <h2 id="menu4child1">商品查询</h2>
            <h4>1.	在统一商品查询按钮里面可以查询商品信息</h4>
            <img src="@/assets/img/product_1.png" />
            <h4>2.	22</h4>
            <img src="@/assets/img/product_2.png" />
            <h4>3.	质检信息</h4>
            <img src="@/assets/img/product_3.png" />
            <h4>4.	物流信息</h4>
            <img src="@/assets/img/product_4.png" />
            <h4>5.	采购信息</h4>
            <img src="@/assets/img/product_5.png" />
            <h4>6.	销量信息</h4>
            <img src="@/assets/img/product_6.png" />
            <h4>7.	在线listing信息</h4>
            <img src="@/assets/img/product_7.png" />
            <h4>8.	新品信息</h4>
            <img src="@/assets/img/product_8.png" />
        
        <!-- 客服管理 -->
        <h1 id="menu5">客服管理</h1>
        
          <!-- Ebay 评价 -->
          <h2 id="menu5child1">Ebay 评价</h2>
            <h4>1.	好评	位置：客服管理系统—评价—好评,选择“未回评”状态，单击“回评”</h4>
            <img src="@/assets/img/evaluation_1.png" />
            <h4>2.	中评	位置：客服管理系统—评价—中评,可以对中评进行“回评”（与好评回评操作一致），也可继续跟进，跟进时，可以给买家发送消息，也可操作“退款退货补发货”等</h4>
            <img src="@/assets/img/evaluation_2.png" />
            <h4>3.	差评	位置：客服管理系统—评价—差评,可在系统内对抓取的差评进行回评或是继续跟进的处理</h4>
            <img src="@/assets/img/evaluation_3.png" />
          
          <!-- Ebay 纠纷：退款 -->
          <h2 id="menu5child2">Ebay 纠纷：退款</h2>
            <h4>1.	对于“退货”，可以直接点击“更新”，更新他的最新状态，也可单击“处理”进入处理界面</h4>
            <img src="@/assets/img/refund_1.png" />
            <h4>2.	处理时，可以选择“全额退款”、“部分退款”、“退货”、“补发货”或是直接给买家“发送留言”</h4>
          
          <!-- Ebay 纠纷：未收货 -->
          <h2 id="menu5child3">Ebay 纠纷：未收货</h2>
            <h4>1.	可直接给买家发送留言沟通</h4>
            <h4>2.	可以对订单操作“全额退款”，直接将钱退给买家</h4>
            <h4>3.	还可以给买家“提供发货信息”：carrier、跟踪号等</h4>
            <h4>4.	 也可直接将“升级”成纠纷</h4>
            <img src="@/assets/img/unreceived.png" />

          <!-- Ebay 纠纷：取消 -->
          <h2 id="menu5child4">Ebay 纠纷：取消</h2>
            <h4>1.	点击订单号进入详情界面，处理取消交易订单，可以直接“接受”，同时“作废订单”，也可“拒绝”，最后点击“回应”即可</h4>
            <img src="@/assets/img/cancel.png" />
        
        <!-- 订单管理 -->
        <h1 id="menu6">订单管理</h1>

          <!-- 统一订单管理 -->
          <h2 id="menu6child1">统一订单管理</h2>
            <h4>1.	打开统一订单管理界面</h4>
            <img src="@/assets/img/order_1.png" />
            <h4>2.	双击任意一条，得到订单明细展示</h4>
            <img src="@/assets/img/order_2.png" />
            <h4>3.	可以点击”退款”按钮进行退款操作</h4>
            <h4>4.	可以点击“发票导出”进行发票导出操作</h4>

      </el-col>
    </el-row>
  </el-row>
</template>

<script>
export default {
  name: 'instructionPage',
  data(){
    return {
      menuList:[
        {label: '系统简介', id: 'menu1', childList: [ { label: '系统操作流程', id: 'menu1child1'}, { label: '系统流程图示', id: 'menu1child2'} ]},
        {label: '刊登管理', id: 'menu2', childList: [ { label: 'EBAY listing刊登管理', id: 'menu2child1'}, { label: 'Ebay listing下架', id: 'menu2child2'} ]},
        {label: '营销管理', id: 'menu3', childList: [ { label: 'Ebay关联营销', id: 'menu3child1'}, { label: 'eBay打折设置', id: 'menu3child2'} ]},
        {label: '商品管理', id: 'menu4', childList: [ { label: '商品查询', id: 'menu4child1'} ]},
        {label: '客服管理', id: 'menu5', 
          childList: [ 
           { label: 'Ebay 评价', id: 'menu5child1'}, { label: 'Ebay 纠纷：退款', id: 'menu5child2'},
           { label: 'Ebay 纠纷：未收货', id: 'menu5child3'}, { label: 'Ebay 纠纷：取消', id: 'menu5child4'} 
          ]
        },
        {label: '订单管理', id: 'menu6', childList: [ { label: '统一订单管理', id: 'menu6child1'} ]},
      ]
    }
  },
  methods:{
    // 展开事件
    handleOpen() {

    },
    // 折叠事件
    handleClose() {
      
    },
    //跳转到指定元素位置
    skipTo(id) {
      const ele = document.querySelector('#'+id)
      this.scrollToPosition(ele.offsetTop, window.scrollY)
    },
    //滚动到指定位置
    scrollToPosition(toTop, nowTop){
      let index = 0
      let perY = (toTop-nowTop)/30
      
      const timer =  window.requestAnimationFrame( function fn() {
        if(index < 30){
          window.scrollTo({ top: nowTop + perY * (index +1) })
          index++
          window.requestAnimationFrame(fn)
        }else{
          window.cancelAnimationFrame(timer)
        }
      })
    }
  }
}
</script>

<style lang="scss" >
  .instructionPageWrapper{

    .contentRow{

      .menuCol{
        position: fixed;
        // left: 0;
        // top: 0;

        // min-height: 400px;
        height: 100%;

        .el-menu-vertical-demo{
          height: 100%;

          .el-submenu__title{
            height: 36px;
            line-height: 36px;
            
            display: flex;
            padding-left: 40px!important;

            span{
              position: absolute;
              width: 100%;
              left: 0;
              padding-left: 40px;
              text-align: left;
            }
            
          }

          .el-menu-item{
            height: 36px;
            line-height: 36px;
            
            text-align: left;
            padding-left: 60px!important;

          }
          
        }
        
      }

      .contentCol{
        margin-left: 12.5%;
        padding-left: 80px;
        padding-right: 80px;

        h1,h2,h3,h4,h5,h6,div,span,p{
          text-align: left;
        }

        h2,h4,h5{
          font-weight: normal;
        }

        // h2{
        //   padding-left: 1rem;
        // }

        // h4{
        //   padding-left: 2rem;
        // }

        img{
          max-width: 100%;
        }
        
      }

    }
    
  }
</style>